<template>
  <div style="display: flex">
    <a-dropdown placement="bottomRight">
      <div class="menu-icon">
        <span class="user_name">
          {{ merchantName }}
        </span>
      </div>
    </a-dropdown>
    <a-dropdown>
      <div class="menu-icon">
        <a-avatar icon="user" :size="23" />
        <span class="user_name" style="font-size: 14px">{{ userName }}</span>
      </div>
      <a-menu slot="overlay" @click="dropClick" mode="vertical">
        <!-- <a-menu-item key="change">
          <span rel="noopener noreferrer">
            <i class="fa fa-lock mr10"></i>
            {{ $t(`修改密码`) }}
          </span>
        </a-menu-item> -->
        <a-menu-item key="logout">
          <span rel="noopener noreferrer">
            <i class="fa fa-sign-out mr10"></i>
            {{ $t(`登出`) }}
          </span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import { Dropdown, Menu, Avatar } from 'ant-design-vue'
import { logout } from '@common'
import { mapMutations } from 'vuex'
export default {
  components: {
    'a-avatar': Avatar,
    'a-dropdown': Dropdown,
    'a-menu-item': Menu.Item,
    'a-menu': Menu,
  },
  props: ['list'],
  data() {
    return {
      merchantName: '',
      userName: '',
      shopList: [],
    }
  },
  mounted() {
    let userSession = localStorage.getItem('userSession')
    if (userSession) {
      userSession = JSON.parse(userSession)
      this.merchantName = userSession.merchantName
      this.userName = userSession.realName
    }
  },
  methods: {
    ...mapMutations(['setState']),
    dropClick(item) {
      if (item.partyId) {
        this.currentShop = item
        try {
          if (this.currentShop.children) {
            delete this.currentShop.children
          }
          this.setState({
            currentShop: this.currentShop,
          })
        } catch (err) {
          console.log(err)
        }
        this.$emit('changeParty', item)
        return
      } else {
        const key = item.key
        console.log(key)
        switch (key) {
          case 'account':
            this.$router.push('/account/index')
            break
          case 'logout':
            this.goToIdaasOut()
            break
          case 'change':
            this.$refs.changePassword.visiable = true
            break
          default:
            break
        }
      }
    },
    goToIdaasOut() {
      if (window.__POWERED_BY_QIANKUN__) {
        this.$erpLogout()
      } else {
        logout()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';

.menu-icon {
  text-align: center;
  padding: 0 10px;

  .menu-icon-item {
    color: var(--PC);
    font-size: 20px;
  }

  .user_name {
    font-weight: bold;
    font-size: 12px;
    padding-left: 10px;
  }
}

.menu-icon:hover {
  background: rgba(0, 0, 0, 0.025);
}

.active {
  color: $error-color;
}

.boxTitle {
  font-weight: 900;
  padding: 2px 4px;
}

.boxText {
  cursor: pointer;
  padding: 4px 4px;
  border-radius: 3px;
  &.active {
    color: $primary-color;
    font-weight: bold;
  }
}

.boxText:hover {
  background-color: #d1e8fd;
}

.box {
  background-color: white;
  margin: -10px;
  border-radius: 4px;
  width: 380px;
  overflow: hidden;
  padding: 12px 8px;
  max-height: 500px;
  overflow-y: auto;
}
</style>
